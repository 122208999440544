import { type UserAvatar as UserAvatarType } from '@harta-io/drizzle'
import { cva, type VariantProps } from 'class-variance-authority'
import { User } from 'lucide-react'
import { type ReactNode } from 'react'
import { useUser, useUserAvatar } from '#app/hooks/user.hooks.ts'
import { cn } from '#app/utils/misc.tsx'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar.tsx'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip.tsx'
import { getUserInitials, getUserAvatarSrc } from './helpers.ts'

export const userAvatarVariants = cva(
  'relative flex h-10 w-10 shrink-0 overflow-hidden',
  {
    variants: {
      size: {
        default: 'h-10 w-10 rounded-full',
        xs: 'h-4 w-4 rounded-full',
        sm: 'h-6 w-6 rounded-full',
        md: 'h-16 w-16 rounded-full',
        fill: 'h-full w-full rounded-xl',
      },
    },
  },
)

export const userAvatarFallbackVariants = cva(
  'bg-muted flex h-full w-full items-center justify-center',
  {
    variants: {
      size: {
        default: 'rounded-full',
        xs: 'text-xs rounded-full',
        sm: 'text-sm rounded-ful',
        md: 'text-2xl rounded-full',
        fill: 'text-body-2xl rounded-xl',
      },
    },
  },
)

interface CurrentUserAvatarProps {
  className?: string
  src?: string | null
}

export function CurrentUserAvatar({
  className,
  size,
  src,
}: CurrentUserAvatarProps &
  VariantProps<typeof userAvatarVariants & typeof userAvatarFallbackVariants>) {
  const user = useUser()
  const userAvatar = useUserAvatar()

  const userInitials = user != null ? getUserInitials(user) : ''

  return (
    <Avatar className={cn(userAvatarVariants({ size }), className)}>
      <AvatarImage
        src={src ?? getUserAvatarSrc(userAvatar?.id)}
        alt={userAvatar?.altText ?? user?.username}
      />
      <AvatarFallback
        className={cn('font-semibold', userAvatarFallbackVariants({ size }))}
      >
        {userInitials}
      </AvatarFallback>
    </Avatar>
  )
}

interface UserAvatarProps {
  className?: string
  userAvatar?: Pick<UserAvatarType, 'id' | 'altText'> | null
  tooltipContent?: ReactNode
}

export function UserAvatar({
  className,
  size,
  userAvatar,
  tooltipContent,
}: UserAvatarProps &
  VariantProps<typeof userAvatarVariants & typeof userAvatarFallbackVariants>) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Avatar className={cn(userAvatarVariants({ size }), className)}>
            <AvatarImage
              src={getUserAvatarSrc(userAvatar?.id)}
              alt={userAvatar?.altText ?? 'user avatar image'}
            />
            <AvatarFallback
              className={cn(
                'font-semibold',
                userAvatarFallbackVariants({ size }),
              )}
            >
              <User className="text-muted-foreground h-full w-full" />
            </AvatarFallback>
          </Avatar>
        </TooltipTrigger>
        {tooltipContent != null && (
          <TooltipContent>{tooltipContent}</TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  )
}
