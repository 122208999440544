import { type User } from '@harta-io/drizzle'
import { type SerializeFrom } from '@remix-run/node'

export function getUserInitials(user: SerializeFrom<User>) {
  return (user.name ?? user.username)
    .split(' ')
    .map(name => name[0]?.toUpperCase() ?? '')
    .join('')
}

export function getUserAvatarSrc(avatarId?: string | null) {
  return avatarId ? `/resources/user-avatar/${avatarId}` : undefined
}
